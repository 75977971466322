import React from 'react';
import Header from '../components/Header';
import {Helmet} from "react-helmet";
import Footer from "../components/Footer"


// Style
import './cgu.scss';


// markup
const CGV = () => {

  return (
    <main className={"cgv"}>
      <Helmet title={"Scorf - Condition Générales de Prestations"}>
        <html lang="fr" />
      </Helmet>
      <title>CONDITIONS GÉNÉRALES DE PRESTATIONS SCORF </title>
      <Header headerColor={'white'}/>
      <h1>CONDITIONS GÉNÉRALES DE PRESTATIONS SCORF </h1>
      <p className="date">Dernière mise à jour le 10/01/2022  </p>
      <div className="content">

        <h3>ARTICLE 1. OBJET </h3>
        <div className="line">
          <p>1.1</p>
          <p>Sauf convention écrite contraire, les présentes conditions générales s'appliquent de plein droit à toute réalisation de prestations (ci-après « Prestations ») par la société SCORF (ci-après : « SCORF »), fournisseur de la solution logicielle « SCORF » telle que décrite ci-après, auprès de l’auteur de la commande (ci-après « Client »), qui les agrée et qui reconnaît en avoir parfaite connaissance et renonce de ce fait à se prévaloir de tout document contradictoire, et notamment ses propres conditions générales d’achat. En conséquence, le fait de passer commande implique l’acceptation et l’adhésion entière et sans réserve du Client à ces conditions générales de prestations, à l’exclusion de tous autres documents du Client ou de SCORF tels que prospectus, catalogues, etc. et qui n’ont qu’une valeur indicative. Aucun autre document que les présentes ne pourra créer d’obligations à la charge des parties ou déroger aux présentes à moins de faire l’objet d’un écrit signé par les parties ou d’une mention dans le devis accepté conformément à l’article 3.2. Le Client accepte que SCORF puisse modifier ultérieurement et raisonnablement les présentes conditions générales et que leur relation sera toujours régie par les dernières conditions en vigueur au jour de la commande.</p>
        </div>

        <h3>ARTICLE 2. DEFINITIONS  </h3>
        <div className="line">
          <p>Pour l’interprétation des présentes, le Client et SCORF conviennent de donner aux termes et expressions ci-après énumérés, les significations suivantes. Administrateur système : désigne la personne, au sein du Client, désignée pour être l’interlocuteur privilégié de SCORF. Application : désigne la solution logicielle « SCORF », incluant les mises-à-jour et documentation, éditée, et commercialisée par SCORF en vue de concéder un droit d’utilisation au Client. Cloud : désigne la plateforme hébergée sur des serveurs informatiques distants à partir de laquelle l’Application est accessible dans le cadre d’un déploiement en mode « SaaS ». Interface Entreprise : désigne l’espace à partir duquel le Client accède aux services offerts sur l’Application. Un établissement s’entend d’une entité juridiquement indépendante au sein du Client disposant d’un numéro SIRET et d’un compte bancaire propres. Offre : désigne l’offre de service sélectionnée par le Client parmi les différentes formules proposées par SCORF dans le cadre de l’accès à l’Application. Prestations : désigne les prestations proposées par SCORF à savoir notamment : </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>la mise à disposition de l’Application en mode « SaaS » par la concession d’une Licence ;  </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>l’assistance technique de l’Application; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>toute autre prestation qui pourrait être proposée par SCORF au Client. </p>
        </div>
        <div className="line">
          <p>Parties : désigne collectivement SCORF, société par actions simplifiée au capital de 8 000€ dont le siège social est situé au 33 bis Route de la Reine 92100 Boulogne-Billancourt, immatriculée au RCS de Nanterre sous le N° 853 834 059, et le Client. Utilisateur : désigne toute personne physique à qui SCORF consent le droit d’utiliser l’Application, à savoir soit une personne au sein du Client, soit un client du Client. Toute référence au singulier inclut le pluriel et inversement. Toute référence à un genre inclut l’autre genre. </p>
        </div>

        <h3>ARTICLE 3. FORMATION DES CONTRATS  </h3>
        <div className="line">
          <p>Il appartient au Client de sélectionner sur proposition de SCORF l’offre qu'il désire commander et notamment le nombre de licence auquel il souhaite souscrire, selon les modalités suivantes : </p>
        </div>
        <div className="line">
          <p>3.1</p>
          <p>Demande d’Inscription sur l’Application. Pour accéder aux offres de Prestations de SCORF, le Client est invité à prendre contact avec SCORF directement depuis le site internet www.scorf.io en cliquant sur « Commencer » ou sur « Planifier une démo ». Pour pouvoir s’inscrire, le Client doit être un professionnel immatriculé au RCS ou au Répertoire des métiers. Le Client est invité à renseigner un email et un numéro de téléphone.</p>
        </div>
        <div className="line">
          <p>3.2</p>
          <p>Enregistrement d'une commande. Pour passer commande, le Client prend connaissance du bon de commande et des Conditions Générales de Prestation envoyés au client, détaillant : la nature et le prix de l’Offre retenue par le Client au tarif HT et TTC en vigueur. Après avoir pris connaissance du bon de commande, le Client aura la possibilité de vérifier d’éventuelles erreurs et de modifier sa commande. Le Client remplit avec exactitude un formulaire dans lequel il fournit ses informations de facturation (dénomination sociale, adresse de facturation) et ses coordonnées de paiement. Le Client demeure seul responsable de l’exactitude des informations données. Une commande comportant des informations incomplètes ou erronées risquerait d’entraîner des erreurs ou des retards qui ne pourraient en aucun cas être imputés à SCORF.</p>
        </div>
        <div className="line">
          <p>3.3</p>
          <p>Validation définitive de la commande. Après avoir pris connaissance du bon de commande, et une fois que l'ensemble des informations demandées, y compris l’adresse de facturation, auront été validées et/ou modifiées par le Client, ce dernier cochera les cases « J'ai pris connaissance de la proposition commerciale et j'en accepte les termes » puis « J'ai lu et j'accepte les Conditions Générales de Prestation » et cliquera sur « Valider la commande ». Ce clic constitue une signature électronique. Cette signature a valeur entre les Parties au même titre qu’une signature manuscrite. Le bon de commande sera enregistré sur les registres informatiques de SCORF, eux-mêmes conservés sur un support fiable et durable, et sera considéré comme preuve des relations contractuelles intervenues entre les Parties. Le Client pourra procéder au paiement de l’Offre dans les conditions de l’article 7.2.</p>
        </div>
        <div className="line">
          <p>3.4</p>
          <p>Récapitulatif de la commande. Lorsqu'il aura validé son mode de paiement, un récapitulatif de la commande du Client lui sera envoyé par email et mentionnera notamment le numéro de la transaction. La vente ne sera considérée comme définitive qu'après envoi du récapitulatif de la commande par SCORF, lequel vaut accusé de réception de la commande (ci-après « le Contrat »).</p>
        </div>
        <div className="line">
          <p>3.5</p>
          <p>Généralités. Sauf preuve contraire, les données enregistrées par SCORF constituent la preuve de l'ensemble des transactions. En tout état de cause, SCORF se réserve le droit de refuser toute commande ou toute livraison en cas de litige existant avec le Client, de non-paiement total ou partiel d’une commande précédente par le Client. La responsabilité de SCORF ne pourra alors être engagée. Le bénéfice de la commande est personnel au Client et ne peut être cédé sans l’accord de SCORF. Toute modification demandée par le Client ne peut être prise en considération que si elle est parvenue par écrit avant la mise à disposition de l’Application et/ou des Prestations, étant précisé que SCORF se réserve seule le droit de donner suite à une telle demande.</p>
        </div>

        <h3>ARTICLE 4. PRESTATIONS  </h3>
        <div className="line">
          <p>4.1</p>
          <p>SCORF s'oblige à apporter le meilleur soin, dans le respect des règles de son art, à l'accomplissement de ses Prestations pour l'exécution desquelles elle s'engage à consacrer les moyens nécessaires. Les obligations de SCORF dépendent de la mission qui lui a été confiée.  </p>
        </div>
        <div className="line">
          <p>4.2</p>
          <p>Mise à disposition de l’Application en mode « SaaS » L’Application est mise à disposition du Client par SCORF sur le Cloud. L’accès à l’Application sera effectué par le Client sur son propre matériel doté de la configuration requise et sous sa propre responsabilité, conformément aux instructions données par SCORF. Ainsi, le Client est informé que l’accès au Logiciel en mode « SaaS » requière qu’il dispose d’un ordinateur ainsi que d’une connexion internet. Une fois le Contrat formé conformément à l’article 3 et sous réserve de l’encaissement du prix tel que prévu à l’article 7.1 ci-après, SCORF ouvre un accès au Client lui permettant d’accéder à l’Application et de l’utiliser. Le Client dispose du nombre de comptes Utilisateurs indiqué dans l’Offre. Le Client est responsable de ses codes de connexion à l’Application et s’engage à en être le seul détenteur. SCORF ne pourra en aucun cas être tenue pour responsable des conséquences d’une utilisation frauduleuse du code de connexion du Client par un tiers. Le Client a la possibilité d’augmenter son Offre pour une Offre supérieure librement en cours d'exécution du Contrat. Le changement d’Offre intervient au jour de sa souscription. La modification du prix du service en résultant interviendra à cette même date.  </p>
        </div>
        <div className="line">
          <p>4.3</p>
          <p>Assistance technique La conclusion d’un Contrat implique la réalisation par SCORF d’une Prestation d’assistance technique. L’assistance technique est fournie soit par téléphone sous forme de hotline, soit par mail. </p>
        </div>
        <div className="line">
          <p>4.4</p>
          <p>Autres services Par ailleurs, pour toute demande de prestation complémentaire qui ne serait pas initialement prévue au Contrat, SCORF adressera au Client un devis complémentaire. Si le Client ne sollicite pas de devis complémentaire, les Prestations seront réalisées au tarif de SCORF en vigueur au jour de la demande du Client.</p>
        </div>

        <h3>ARTICLE 5. OBLIGATIONS DU CLIENT  </h3>
        <div className="line">
          <p>5.1</p>
          <p>Tous les Droits de Propriété Intellectuelle relatifs à notre Site (notamment tous les brevets, droits sur les inventions, les textes, les images, les vidéos, les séquences audio, la mise en forme, les graphismes, la structure, les marques et tout autre droit de propriété intellectuelle, dans chaque cas, qu'ils soient enregistrés ou non, y compris tou(te)s les demandes et renouvellements ou extensions de ces droits, et tou(te)s les droits similaires ou équivalents ou formes de protection dans toute partie du monde), à l'exception du contenu de votre compte, sont notre propriété exclusive ou celle de tiers titulaires de droits. </p>
        </div>
        <div className="line">
          <p>Il appartient au Client : </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de déclarer des informations complètes et exactes lors de sa commande, et à les mettre à jour en cas de changement ; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de désigner un correspondant compétent qui reste l'interlocuteur de SCORF pendant toute la durée de la Prestation ; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de s'assurer que les instructions nécessaires parviennent en temps voulu à SCORF pour lui permettre de remplir normalement sa Prestation ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de remettre ou de faire remettre par ses partenaires, dans les délais convenus, tous les documents de travail, fichiers, éléments (etc.) nécessaires à la réalisation des Prestations; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>d’utiliser l’Application uniquement dans le cadre de son activité professionnelle et en tout état de cause à ne pas l’utiliser à des fins non prévues par les présentes conditions générales ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de ne pas usurper d’identité ni tenter de se connecter à un compte autre que le sien ; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de ne pas se livrer à des analyses du système assimilables à du reverse engineering ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de ne pas supprimer tout ou partie de l’Application ni de son environnement technique sans autorisation de SCORF ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de ne pas télécharger de virus ou de fichiers potentiellement dangereux pour l’intégrité de l’Application.</p>
        </div>
        <div className="line">
          <p>Si SCORF doit clôturer le compte d’un Client pour non-respect de ses obligations au titre des présentes conditions générales, SCORF sera en droit de résilier le Contrat conformément à l’article 13.2, sans préjudice de tout dommages-intérêts que SCORF pourrait être en droit de demander, ainsi que d’éventuelles poursuites pénales lorsque le comportement est susceptible d’être qualifié d’infraction pénale. </p>
        </div>

        <h3>ARTICLE 6. LICENCE ET DROITS CONCEDES AU CLIENT </h3>
        <div className="line">
          <p>SCORF consent au Client la possibilité d’utiliser l’Application. La licence apparaîtra à l’écran du poste ordinateur de destination de l’Application lors de la création du compte du Client par l’Administrateur système, en vue de son acceptation par celui-ci. Le Client se porte fort du bon respect par les Utilisateurs de l’Application des dispositions figurant dans la licence. Aucun autre droit que ceux visés dans la licence n’est accordé au Client.  </p>
        </div>

        <h3>ARTICLE 7. PRIX ET PAIEMENT DU PRIX </h3>
        <div className="line">
          <p>7.1</p>
          <p>Le prix est celui figurant au Contrat formé conformément à l’article 3 et dépend de l’Offre ou des Offres sélectionnée(s) par le Client et du nombre d’Interfaces Entreprise créées par le Client pour lesquelles il souhaite souscrire un abonnement. Le prix se compose d’une redevance périodique d’utilisation de l’Application qui inclut l’assistance technique associée telle que décrite à l’article 4.3. Le prix s’entend toujours hors taxes et en euros. </p>
        </div>
        <div className="line">
          <p>7.2</p>
          <p>A défaut de clause contraire dans le Contrat, le prix de la redevance mentionnée à l’article 7.1 est payable à la commande, par prélèvement SEPA via le prestataire de paiement en ligne « GoCardless ». En procédant au paiement, le Client déclare accepter les conditions générales d’utilisation « GoCardless » disponibles à l’adresse suivante : https://gocardless.com/fr-fr/legal. </p>
        </div>
        <div className="line">
          <p>7.3</p>
          <p>A l’issue de la Durée Initiale mentionnée à l’article 13.1, puis à chaque date de renouvellement, le prix de la redevance périodique pourra être révisé annuellement suivant l’indice SYNTEC selon la formule suivante : P1=P0x(S1/S0) avec P0 le prix d’origine de la redevance, P1 le prix révisé de la redevance, S0 le dernier indice SYNTEC publié à la date de la précédente révision du prix et S1 le dernier indice SYNTEC publié à la date de la révision du prix. </p>
        </div>
        <div className="line">
          <p>7.4</p>
          <p>Toute somme non payée dans les délais prévus sera productive d'intérêts à un taux égal au taux d’intérêt appliqué par la Banque centrale européenne à son opération refinancement la plus récente majoré de 10 points de pourcentage et ouvrira droit au paiement d’une somme forfaitaire de 40 euros au titre de l’indemnité pour frais de recouvrement. Les pénalités de retard sont exigibles dès le premier jour de retard sans qu’une mise en demeure soit nécessaire et courent jusqu'au paiement intégral de la totalité des sommes dues, intérêts compris. </p>
        </div>
        <div className="line">
          <p>7.5</p>
          <p>Le Client ne pourra invoquer quelque cause que ce soit pour différer ou modifier les conditions de paiement ou demander une réduction de prix, notamment une contestation sur la qualité ou non-conformité des Prestations. En cas de défaut de paiement dans les délais prévus et 48 heures après une mise en demeure restée infructueuse, SCORF aura la faculté d’exiger le paiement immédiat du solde restant dû, de suspendre la réalisation de ses obligations, sans préjudice de demander des dommages et intérêts et/ou la résiliation du contrat conformément à l’article 13.2 ci-après. </p>
        </div>

        <h3>ARTICLE 8. PROPRIETE INTELLECTUELLE  </h3>
        <div className="line">
          <p>8.1</p>
          <p>L’Application est protégée par le droit d’auteur et par les dispositions qui suivent. L’ensemble des droits de propriété intellectuelle relatifs à l’Application ainsi que tous les éléments qui la composent (documentation, droits d’auteurs…) sont la propriété pleine et entière de SCORF, ses dirigeants et/ou partenaires. Le Client s’engage à ne pas porter atteinte, directement ou indirectement ou par l’intermédiaire de tiers auxquels il est associé, auxdits droits.  </p>
        </div>
        <div className="line">
          <p>8.2</p>
          <p>En cas d’utilisation excédant l’utilisation définie dans le Contrat et dans la licence d’utilisation, le Client s’expose à une action en contrefaçon. </p>
        </div>
        <div className="line">
          <p>8.3</p>
          <p>Dans le cadre de la mise à disposition de l’Application au Client, SCORF concède un droit personnel, non cessible et non exclusif d’utilisation de l’Application. Le droit d’utilisation de l’Application est exclusif de la concession de tout autre droit et n’emporte en aucun cas le droit de faire tout acte non expressément autorisé et notamment les droits de copie, de traduction, d’adaptation, d’arrangement ou toute autre modification de l’Application et/ou de ses composants. </p>
        </div>
        <div className="line">
          <p>8.4</p>
          <p>Le cas échéant, le Client garantit que tous les éléments remis à SCORF dans le cadre de la réalisation des Prestations n’empiètent pas sur les droits de propriété intellectuelle des tiers. Il garantit SCORF contre toute conséquence de toute nature (incluant frais de procédure, honoraires d’avocat, dommages et intérêts et indemnités…) dans l’hypothèse où la responsabilité de SCORF serait recherchée par un tiers. </p>
        </div>
        <div className="line">
          <p>8.5</p>
          <p>SCORF pourra faire état du nom du Client, agissant dans le cadre de son activité professionnelle, à titre de référence commerciale, sauf avis contraire écrit de ce dernier.</p>
        </div>

        <h3>ARTICLE 9. GARANTIE </h3>      
        <div className="line">
          <p>Le Client est réputé connaître parfaitement les Prestations qu’il acquiert et reconnaît qu’il a pu se procurer les renseignements relatifs à celles commandées et qu’il les a compris. Le Client détermine seul la destination et l’usage de l’Application. Toute demande du Client de modification des spécifications par rapport à l’offre formulée par SCORF intervient sous la seule et unique responsabilité du Client. De la même manière, toutes spécifications incomplètes risqueraient d’entraîner des erreurs qui ne pourront jamais être imputées à SCORF. </p>
        </div>

        <h3>ARTICLE 10. RESPONSABILITE </h3>
        <div className="line">
          <p>10.1</p>
          <p>En cas de doute sur l’interprétation d’une clause ou en l’absence de mention permettant de déterminer l’étendue des obligations de SCORF, le Client reconnaît que les obligations de SCORF s’entendront comme des obligations de moyens. </p>
        </div>
        <div className="line">
          <p>10.2</p>
          <p>SCORF ne peut en aucun cas voir sa responsabilité engagée à l’égard du Client lorsque les Prestations livrées sont conformes à celles commandées. La conformité à la commande s’apprécie par référence au Contrat. Par ailleurs, lorsque SCORF formule, dans le cadre de la réalisation de la Prestation, un ensemble de recommandations et de préconisations, SCORF ne peut en aucun cas voir sa responsabilité engagée à l’égard du Client lorsque ces recommandations ne sont pas suivies par le Client.</p>
        </div>
        <div className="line">
          <p>10.3</p>
          <p>Le Client reconnaît et accepte que SCORF n’est pas responsable du contenu que le Client et les Utilisateurs publient sur l’Application et notamment des chiffres qu’ils intègrent ainsi que leur véracité, qui sont de leur seule responsabilité. En aucun cas, SCORF ne saurait être responsable d’une quelconque difficulté financière que rencontrerait le Client et/ou d’une mauvaise gestion de sa comptabilité et/ou de sa trésorerie par ce dernier. </p>
        </div>
        <div className="line">
          <p>10.4</p>
          <p>Toute contestation par le Client de la bonne exécution par SCORF de ses obligations contractuelles devra être motivée et faire l'objet d'une lettre recommandée avec accusé de réception adressée au plus tard dans l’année de la date de découverte des faits susceptibles de fonder ladite réclamation. Le défaut de procéder ainsi vaut renonciation de la part du Client à critiquer la bonne exécution par SCORF de ses obligations contractuelles. </p>
        </div>
        <div className="line">
          <p>10.5</p>
          <p>En aucun cas, sauf faute lourde ou dolosive, SCORF ne sera tenue à réparation du préjudice indirect (préjudice moral ou commercial, pertes de données, déficit d’image, perte de bénéfice, de chiffre d’affaires, de commande ou de clientèle) que pourrait subir le Client du fait de la réalisation des Prestations. EN OUTRE, DANS SES RAPPORTS AVEC LE CLIENT, EN AUCUN CAS LA RESPONSABILITE DE SCORF, QUI DECOULERAIT D’UN PREJUDICE NÉ DE LA REALISATION DES PRESTATIONS, NE POURRA EXCEDER LE MONTANT PAYE PAR LE CLIENT AU TITRE DU CONTRAT PENDANT LES DOUZE DERNIERS MOIS. </p>
        </div>

        <h3>ARTICLE 11. CONFIDENTIALITE  </h3>
        <div className="line">
          <p>Chacune des Parties considère comme strictement confidentiels et s’interdit de divulguer toute information, donnée, formule ou concept de l’autre Partie qui sont mises à la disposition de l'autre Partie sous quelque forme que ce soit (écrite, orale, par supports magnétiques, électroniques, informatiques, etc.) à l’occasion du Contrat (ci-après « Informations Confidentielles ») pendant une durée de cinq (5) années à compter de leur divulgation. Aucune des Parties ne divulguera d'Information Confidentielle concernant l'autre à un tiers sans le consentement exprès et écrit de cette autre Partie, et ne fera usage d'aucune Information Confidentielle autrement que pour l'exécution du contrat. Chaque Partie doit apporter le même degré de précaution à la non- divulgation d'Informations Confidentielles que celui qu'elle apporte à ses propres Informations Confidentielles. Les Informations Confidentielles ne comprennent pas les informations : (a) généralement disponibles au public ou connues de celui-ci, (b) connues antérieurement du destinataire, (c) développées indépendamment par le destinataire en dehors du champ du contrat formé conformément à l’article 3, (d) divulguées licitement par un tiers, ou à l'occasion d'un témoignage devant une autorité compétente. Les deux Parties prendront vis-à-vis de leur personnel et partenaires toutes les mesures nécessaires pour assurer l’effectivité de l’obligation mentionnée ci-dessus. </p>
        </div>

        <h3>ARTICLE 12. DONNEES PERSONNELLES  </h3>
        <div className="line">
          <p>12.1</p>
          <p>Le Client est informé et accepte qu'en passant commande et en utilisant l’Application, SCORF procède à des traitements de ses données personnelles aux fins de gestion de la commande et de fourniture des Prestations et ce, conformément aux dispositions de la loi Informatique et Libertés du 6 janvier 1978 modifiée par la loi n° 2018-493 du 20 juin 2018 relative à la protection des données personnelles issue du Règlement Général Européen sur la protection des Données Personnelles. Pour plus d’information sur la manière dont SCORF réalise les traitements de données personnelles, le Client est invité à consulter la politique de confidentialité de SCORF disponible à l’adresse https://www.scorf.io/policy (« Politique de confidentialité »). </p>
        </div>
        <div className="line">
          <p>12.2</p>
          <p>Si, dans le cadre de la réalisation d’une Prestation commandée par le Client, SCORF est amenée à traiter des données personnelles d’Utilisateurs, les termes prévus en Annexe des présentes s’appliqueront.</p>
        </div>

        <h3>ARTICLE 13. DUREE - RESILIATION </h3>
        <div className="line">
          <p>13.1</p>
          <p>Selon définition du bon de commande, le contrat est conclu :<br></br>
            <ul>
            <li> - pour une durée d’un (1) mois (« Durée Initiale »). A l’issue de la Durée Initiale, le Contrat se renouvelle tacitement pour des périodes successives d’un (1) mois, sauf dénonciation par l’une ou l’autre des Parties au moins sept (7) jours avant la date anniversaire du Contrat. </li>
            <li> - pour une durée d’un (1) an (« Durée Initiale »). A l’issue de la Durée Initiale, le Contrat se renouvelle tacitement pour des périodes successives d’un (1) an, sauf dénonciation par l’une ou l’autre des Parties au moins sept (7) jours avant la date anniversaire du Contrat. </li>
            </ul>
          </p>
          
        </div>
        <div className="line">
          <p>13.2</p>
          <p>Nonobstant ce qui précède, chacune des Parties a le droit de résilier le Contrat à tout moment et de plein droit, par lettre recommandée avec accusé de réception : - en cas d’inexécution totale ou partielle de ses obligations par l’autre Partie, notamment le non-respect des obligations telles que définies à l’article 5, de l’obligation de paiement, de l’obligation de confidentialité, le respect des droits de propriété intellectuelle de SCORF et plus généralement, l’exécution loyale du Contrat, trente (30) jours après une mise en demeure adressée par lettre recommandée avec accusé de réception et restée infructueuse et contenant déclaration par la Partie lésée de son intention d’user du bénéfice de la présente clause, sans préjudice de la réparation de tous dommages directs et indirects que pourrait lui causer cette résiliation ; - en cas de modification défavorable dans la situation financière ou commerciale du Client, risquant de déboucher sur un défaut de paiement. Dans le cas où, du fait de la nature de l’obligation inexécutée, il n’est pas possible pour la Partie défaillante d’y remédier (exemple : manquement à une obligation de ne pas faire), le Contrat pourra être résilié de plein droit par l’une ou l’autre des Parties sans mise en demeure préalable.</p>
        </div>

        <h3>ARTICLE 14. FORCE MAJEURE </h3>
        <div className="line">
          <p>14.1</p>
          <p>Les cas de force majeure suspendront les obligations des Parties. En cas de survenance d'un tel événement, les Parties s'efforceront de bonne foi de prendre toutes les mesures raisonnablement possibles en vue de poursuivre l'exécution du Contrat. Si les cas de force majeure ont une durée d'existence supérieure à soixante (60) jours, le présent Contrat pourra être résilié sur l'initiative de l'une ou l'autre des Parties, sans droit à indemnité de part et d'autre. </p>
        </div>
        <div className="line">
          <p>14.2</p>
          <p>Sont expressément considérés comme des cas de force majeure : lock-out, grève, épidémie, embargo, accident, intempéries exceptionnelles, interruption ou retard dans les transports, ou tout autre événement indépendant de la volonté de SCORF entraînant notamment un chômage total ou partiel chez SCORF, le blocage total ou partiel des moyens de communications, y compris les réseaux.</p>
        </div>

        <h3>ARTICLE 15. CESSION – SOUS-TRAITANCE </h3>
        <div className="line">
          <p>15.1</p>
          <p>Le Contrat ne pourra en aucun cas faire l’objet d’une cession totale ou partielle, à titre onéreux ou gratuit, de la part du Client, sans autorisation écrite, expresse et préalable de SCORF. </p>
        </div>
        <div className="line">
          <p>15.2</p>
          <p>SCORF se réserve le droit de faire appel à tout sous-traitant de son choix. SCORF se réserve également le droit de céder le présent Contrat sans formalités, sous réserve d’en informer préalablement le Client. </p>
        </div>

        <h3>ARTICLE 16. LOI APPLICABLE - DIFFERENDS </h3>
        <div className="line">
          <p>Toutes les clauses figurant dans les présentes conditions générales ainsi que toutes les opérations contractuelles qui y sont visées sont soumises au droit français, à l’exception de toute convention internationale. TOUT LITIGE DECOULANT DES OPERATIONS VISEES AUX PRESENTES, MEME EN CAS DE PLURALITE DE DEFENDEUR OU D’APPEL EN GARANTIE, SERA DE LA COMPETENCE EXCLUSIVE DU TRIBUNAL DE COMMERCE DE PARIS (FRANCE), CE QUI EST EXPRESSEMENT ACCEPTE PAR LE CLIENT. </p>
        </div>

        <h3>ARTICLE 17. TOLERANCE – NULLITE PARTIELLE </h3>
        <div className="line">
          <p>17.1</p>
          <p> Aucun fait de tolérance de la part de l’une des Parties à l’égard de l’autre ne pourra s’interpréter comme valant renonciation à se prévaloir de l’intégralité des droits qu’elle se voit octroyés par les présentes conditions générales, en particulier une tolérance relative aux délais de paiement.</p>
        </div>
        <div className="line">
          <p>17.2</p>
          <p> L’éventuelle annulation d’une clause n’affectera pas la validité des autres clauses des présentes.</p>
        </div>

        <h3>ARTICLE 18. PREUVE </h3>
        <div className="line">
          <p> En cas de litige, les Parties acceptent de considérer l’e-mail et les échanges via l’Application comme un écrit original valant preuve parfaite et renoncent à contester ce moyen de preuve, sauf à discuter son authenticité. </p>
        </div>

        <h3>Annexe </h3>
        <div className="line">
          <p> Sous-traitance de données personnelles </p>
        </div>
        <br />
        <div className="line">
          <p> Objet, durée des traitements et catégories de personnes concernées. Dans le cadre de la fourniture des Prestations, SCORF pourra avoir accès, en qualité de sous-traitant, à des données à caractère personnel au sens de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés et du règlement européen 2016/679 du 27 avril 2016 relatif aux données à caractère personnel (ci-après dénommé « Règlement »). SCORF pourra ainsi être amenée à procéder à des traitements de telles données pour le compte du Client, responsable de traitement, aux seules fins de fourniture desdites Prestations et pour la durée prévue au Contrat. </p>
        </div>
        <br />
        <div className="line">
          <p> Nature et finalité(s) des traitements. SCORF s’engage à ne pas traiter les données personnelles transmises pour des finalités autres que celles du Client, c’est-à-dire la réalisation des Prestations visées dans le Contrat.  </p>
        </div>
        <br />
        <div className="line">
          <p>Registre. SCORF tient tous les registres requis et dont le contenu est défini par l’article 30(2) du Règlement et les met à disposition sur demande.  </p>
        </div>
        <br />
        <div className="line">
          <p>Délégué à la protection des données à caractère personnel. Conformément aux articles 38 et 39 du Règlement, le délégué à la protection des données à caractère personnel ou le service responsable de la protection des données personnelles de SCORF peut être contacté aux coordonnées figurant dans la Politique de confidentialité.  </p>
        </div>
        <br />
        <div className="line">
          <p>Obligations du Client vis-à-vis de SCORF. Le Client s’engage à :  </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>fournir à SCORF toutes les données nécessaires à la réalisation de ses obligations en vertu du Règlement ; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>documenter par écrit toute instruction concernant le traitement des données par SCORF ; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>veiller au préalable et pendant toute la durée du traitement au respect des obligations prévues par le Règlement de la part de SCORF ; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>s’engage à répondre dans un délai de quinze (15) jours à toute demande de SCORF relative au traitement des données personnelles dans le cadre de la fourniture de la Prestation; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>respecter les obligations qui lui incombent en sa qualité de responsable de traitement, en vertu des dispositions du Règlement. </p>
        </div>
        
        <br />
        <div className="line">
          <p>Obligations de SCORF vis-à-vis du Client. SCORF garantit qu’elle met en œuvre toutes les mesures nécessaires pour préserver la sécurité, l’intégrité, la disponibilité, la résilience et la confidentialité de ces données à caractère personnel auxquelles elle pourrait accéder ou qui pourraient lui être communiquées dans le cadre de l’exécution du Contrat. Aussi, SCORF s’engage à prendre toutes les mesures requises en vertu de l’article 32 du Règlement et notamment les mesures techniques et organisationnelles appropriées, compte tenu de l’état des connaissances, des coûts de mise en œuvre et de la nature, de la portée, du contexte et des finalités des traitements, qui seraient nécessaires au respect par lui-même et par son personnel de ces obligations de sécurité, d’intégrité et de confidentialité, et notamment à : </p>
        </div>
        
        <br />
        <div className="linePuce">
          <p>-</p>
          <p>ne traiter, consulter ces données à caractère personnel et fichiers que dans le cadre des instructions du Client, y compris en ce qui concerne les transferts de données à caractère personnel vers un pays tiers ou une organisation internationale, à moins qu'il ne soit tenu d'y procéder en vertu du droit de l'Union européenne ou de la législation française ; dans ce cas, SCORF informera le Client de cette obligation avant le traitement, sauf si la loi interdit une telle information pour des motifs importants d'intérêt public;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>ne pas traiter, consulter lesdites données à caractère personnel ou les fichiers dans lesquelles elles figurent à d’autre fins que l’exécution de la Prestation qu’elle effectue pour le Client au titre du Contrat ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>ne pas insérer dans les traitements de données à caractère personnel des données étrangères auxdits traitements; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>prendre toute mesure permettant d’empêcher toute utilisation détournée, malveillante ou frauduleuse de ces données à caractère personnel et des fichiers;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>prendre toutes précautions utiles afin de préserver la sécurité desdites données à caractère personnel, de veiller à ce qu’elles ne soient pas déformées, endommagées, que des tiers non autorisés y aient accès, et d’empêcher tout accès qui ne serait pas préalablement autorisé par le responsable de traitement;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>prendre toutes mesures afin (i) de garantir la confidentialité, l’intégrité, la disponibilité et la résilience constantes des systèmes et services de traitement utilisés, (ii) de rétablir la disponibilité des données à caractère personnel et l’accès à celles-ci dans les délais appropriés en cas d’incident physique ou technique et (iii) de tester, analyser et évaluer régulièrement l’efficacité de ces mesures ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>s’interdire la consultation et le traitement des données à caractère personnel autres que celles concernées par les présentes et ce, même si l’accès à ces données à caractère personnel est techniquement possible ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>veiller à ce que les personnes autorisées à traiter les données à caractère personnel s’engagent par écrit à respecter la confidentialité de ces dernières ou soient soumises à une obligation légale appropriée de confidentialité, et reçoivent la formation nécessaire en matière de protection des données à caractère personnel ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>ne pas divulguer, sous quelque forme que ce soit, tout ou partie desdites données à caractère personnel ; </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>ne pas prendre copie ou stocker, quelles qu'en soit la forme et la finalité, tout ou partie desdites données à caractère personnel contenues sur les supports ou documents qui lui ont été confiés ou recueillies par elle au cours de l'exécution du Contrat (outre les opérations techniques strictement nécessaires à l’exécution du Contrat).  </p>
        </div>
        
        <br />
        <div className="line">
          <p>Suppression et réversibilité des données. En fin de Contrat, SCORF s’engage à procéder à la restitution des fichiers détenus et des données à caractère personnel traitées pour le compte du Client dans les conditions prévues au Contrat, et à la destruction de tous fichiers manuels ou informatisés stockant lesdits fichiers et données à caractère personnel (et de toute copie éventuelle) après s’être assurée auprès du Client que ce dernier dispose bien de ces informations, à moins que le droit de l’Union européenne ou la législation française n’exige la conservation de ces données à caractère personnel. </p>
        </div>
        
        <br />
        <div className="line">
          <p>Sous-traitants ultérieurs. Par ailleurs, SCORF s’engage à informer et à signer un contrat écrit avec chaque sous-traitant imposant à ce dernier le respect des normes édictées par le Règlement, étant précisé qu’en cas de non-respect par un sous-traitant de ses obligations en matière de protection des données à caractère personnel, SCORF demeurera pleinement responsable à l’égard du Client. Le Client donne par les présentes son consentement général pour le recrutement d'autres sous-traitants à des fins de traitement des données à caractère personnel. SCORF fournira un mécanisme permettant au Client, sur demande de sa part, d'être averti de la mise à jour de la liste des sous-traitants. </p>
        </div>
        
        <br />
        <div className="line">
          <p>Sécurité et confidentialité. Au titre de la sécurité et de la confidentialité des données personnelles, SCORF s’engage à (i) garder les données personnelles strictement confidentielles, (ii) mettre en œuvre au sein de ses services en ce compris son infrastructure d’hébergement, les mesures organisationnelles et techniques appropriées afin de protéger les données personnelles, et (iii) établir, maintenir et fournir à première demande la description des mesures mis en œuvre pour protéger les données personnelles (étant rappelé que le Client est seul responsable de la sécurité, des modalités d’accès et de la protection des données personnelles sur son propre système d’information). Compte tenu de l'état des connaissances, des coûts de mise en œuvre, de la nature, de la portée, du contexte et des finalités du traitement, ainsi que des risques pour les droits et libertés des personnes physiques dont le degré de probabilité et de gravité varie, les Parties mettront en place des mesures techniques et organisationnelles appropriées, afin d'assurer un niveau de sécurité adapté aux risques, incluant notamment, selon les besoins : </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>des moyens permettant de garantir la confidentialité, l’intégrité, la disponibilité et la résilience constantes des systèmes et services de traitement ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>des moyens permettant de rétablir la disponibilité des données à caractère personnel et l’accès à ces dernières en temps utiles en cas d’incident physique ou technique ; et</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>une procédure permettant de tester, d’analyser et d’évaluer régulièrement l’efficacité des mesures techniques et organisationnelles, afin d’assurer la sécurité du traitement. Lors de l'évaluation du niveau de sécurité approprié, il conviendra de tenir compte en particulier des risques que présente le traitement, liés notamment à la destruction, à la perte, à l'altération, à la divulgation non autorisée de données à caractère personnel transmises, conservées ou traitées d'une autre manière, ou à l'accès à de telles données et ce, de manière accidentelle ou illicite. Les Parties prendront des mesures pour garantir que toute personne physique agissant sous l'autorité de l'une ou l'autre Partie et ayant accès aux données à caractère personnel les traite uniquement sur instruction de sa part, sauf si elle est tenue de les traiter par le droit de l'Union ou d'un État membre. </p>
        </div>
        
        <br />
        <div className="line">
          <p>Collaboration. SCORF s’engage également à coopérer avec le Client en vue : </p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de l’avertir dans les meilleurs délais de toutes demandes de personnes concernées reçues, et de coopérer raisonnablement avec lui afin de lui permettre de respecter ses obligations en vertu du Règlement en relation avec de telles demandes. Le Client supportera tous frais raisonnables découlant de l'assistance que SCORF fournira au regard du respect de telles obligations ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>du respect par le Client de ses propres obligations en matière de sécurité et de confidentialité des données à caractère personnel ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>du respect de l’obligation de notification à l'autorité de contrôle et d’information de la personne concernée d'une violation de données à caractère personnel. SCORF avertira le Client dans les meilleurs délais dès connaissance d'une violation des données à caractère personnel et répondra raisonnablement à aux demandes d'informations supplémentaires du Client, afin de l’aider à remplir ses obligations en vertu des articles 33 et 34 du Règlement ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>d’informer dans les meilleurs délais le Client si, selon lui, une instruction constitue une violation du Règlement ou d’autres dispositions du droit de l’Union européenne ou du droit des Etats membres relatives à la protection des données ;</p>
        </div>
        <div className="linePuce">
          <p>-</p>
          <p>de la réalisation d’analyses d’impact relatives à la protection des données ou en cas de consultation préalable de la CNIL par le Client.  </p>
        </div>
        <br />

        <div className="line">
          <p>Contrôle et audit. Le Client se réserve le droit de procéder, à ses frais, à toute vérification qui lui paraîtrait utile pour constater le respect par SCORF de ses obligations au titre des présentes, notamment au moyen d’audits ou d’inspections. Ces vérifications pourront être réalisées maximum une fois par an, par le Client lui-même ou par un tiers qu’il aura sélectionné, missionné et mandaté à cette fin, non concurrent de SCORF. Dans ce cadre, SCORF mettra à la disposition du Client ou dudit tiers les informations nécessaires pour apporter la preuve du respect des obligations prévues au sein du présent Contrat, et s’engage à contribuer auxdites vérifications. Les audits doivent permettre une analyse du respect par le Client des présentes et des dispositions applicables en matière de protection des données à caractère personnel, et notamment de s’assurer que des mesures techniques et organisationnelles de sécurité et de confidentialité adéquates sont mises en en œuvre, qu’elles ne peuvent pas être contournées sans que cela ne soit détecté et que, dans une telle hypothèse ou dans toute autre hypothèse de survenance d’une violation de données à caractère personnel, une procédure de notification et de traitement par SCORF est mise en œuvre afin d’y remédier sans délai. Plus généralement, chacune des Parties garantit à l’autre le respect des obligations légales et règlementaires lui incombant en matière de protection des données à caractère personnel.  </p>
        </div>
        <br />
        <div className="line">
          <p>Transfert des données. SCORF veillera à ce que, dans la mesure où de quelconques données à caractère personnel provenant de l’Union Européenne sont transférées par ses soins à un autre sous-traitant dans un pays ou territoire en dehors de la France et/ou de l’Union Européenne n'ayant pas fait l'objet d'une décision d'adéquation contraignante de la part de la Commission européenne ou de l'autorité nationale compétente en matière de protection des données, un tel transfert soit soumis à un mécanisme de transfert approprié, assurant un niveau de protection adéquat aux termes du Règlement. </p>
        </div>
        <br />
        <div className="line">
          <p>Responsabilité. En toute hypothèse, il est rappelé que la Prestation fournie par SCORF constitue un élément contributif mais non suffisant à la mise en conformité du Client avec l’ensemble des exigences réglementaires en matière de protection des données, et que la responsabilité de SCORF en matière de conformité à la réglementation est strictement limitée au périmètre de la Prestation opérée par ses soins. Le Client devra disposer, sans que cette liste ne revête un caractère exhaustif, d’un système d’information adapté au traitement des données personnelles, d’une analyse de risques et d’impacts le cas échéant, d’une politique de sécurité de son système d’information, d’une charte d’utilisation des moyens informatiques, d’un programme de formation et de sensibilisation de ses utilisateurs à la sécurité et à la protection des données, sous sa seule responsabilité. En aucun cas la responsabilité de SCORF ne peut être recherchée en cas de non-respect par le Client des mesures organisationnelles et techniques de protection des données personnelles lui incombant, ni plus généralement dans la détermination par ses soins des catégories de données collectées et/ou chargées par ses soins au sein des Prestations, des finalités poursuivies et des traitements mis en œuvre par ses soins ou à sa demande. </p>
        </div>


      </div> 
      <Footer setHeaderColor={'white'} />
    </main>
  )
}

export default CGV

